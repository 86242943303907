import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog-16-image001.png"
import image1 from "../../images/blog-16-image003.jpg"
import image2 from "../../images/blog-16-image005.png"

export default function FiveTipsToDitchYourBackPain(){
    return(
        <BlogPostOld
            src={image}
            title={"What Can I Do to Relieve my Back Pain?"}
            summary={"Back pain is a very common condition and causes more disability than any other condition. So if you are feeling this, you are not alone and we see many people like yourself struggle with this condition. "}
            date={"July 18, 2022"}
            author={"Dr. Donald Mull, DC"}
        >
            <p>
                Back pain is a very common condition and causes <a href="https://pubmed.ncbi.nlm.nih.gov/24665116/">
                    more disability than any other condition
                </a>
                . So if you are feeling this, you are not alone and we see many people like
                yourself struggle with this condition.
            </p>
            <p>
                <strong>
                    “I was told to just avoid this activity and I will be good”
                </strong>
            </p>
            <p>
                Which by the way happens to be what you truly enjoy for exercise (we’ve
                been there!). As you will find out later in this article, oftentimes
                avoiding activity completely is NOT helpful.
            </p>
            <p>
                Also being told to avoid something without a plan of action doesn’t get you
                closer to where you want to go. Yes, being told what not to do is
                important, but having guidance throughout the process is a GAME CHANGER.
            </p>
            <p>
                What is the fastest way to relieve my back pain?
            </p>
            <p>
                Oftentimes people are concerned with exercise and are unsure if they are
                doing harm by moving it. As you saw above, many people are told to simply
                avoid activity in order to feel better. We know now that this advice is
                very short sided and may be leading to more harm than good.
            </p>
            <p>
                We now know that if you <a
                    href="https://bmcmusculoskeletdisord.biomedcentral.com/articles/10.1186/s12891-019-3002-5"
                >
                    increase your physical activity your risk of having low back pain
                    decreases by almost 20%
                </a>
                . That is HUGE! Bed rest should not be the advice we are giving clients.
            </p>
            <p>
                <img
                    border="0"
                    width="247"
                    height="439"
                    src={image1}
                />
            </p>
            <p>
                So it is important to continue your physical activity to some level as soon
                as possible. It is often helpful to see a practitioner for help and
                guidance getting back to the things you like to do if you feel limitations.
            </p>
            <p>
                This is what we specialize in. We love to teach you how to modify or build
                back up to being even stronger and more resilient than you were before your
                symptoms. If you need some help,    <a href="https://kineticimpact.janeapp.com/">let’s get started!</a>
            </p>
            <p>
                We have also created a great resource that includes gentle exercises that
                progress through 10 weeks to get you feeling better. All for $5!    <a href="https://www.kirehab.com/lowbackprogram">Check it out Here!</a>
            </p>
            <p align="center">
                <img
                    border="0"
                    width="327"
                    height="374"
                    src={image2}
                />
            </p>
            <p>
                <strong></strong>
            </p>
            <p>
                References:
            </p>
            <ol start="1" type="1">
                <li>
                    Hoy D, March L, Brooks P, Blyth F, Woolf A, Bain C, Williams G, Smith
                    E, Vos T, Barendregt J, Murray C, Burstein R, Buchbinder R. The global
                    burden of low back pain: estimates from the Global Burden of Disease
                    2010 study. Ann Rheum Dis. 2014 Jun;73(6):968-74. doi:
                    10.1136/annrheumdis-2013-204428. Epub 2014 Mar 24. PMID: 24665116.
                </li>
                <li>
                    Kirsch Micheletti, J., Bláfoss, R., Sundstrup, E. <em>et al.</em>
                    Association between lifestyle and musculoskeletal pain: cross-sectional
                    study among 10,000 adults from the general working population.        <em>BMC Musculoskelet Disord</em> 20, 609 (2019).
                    https://doi.org/10.1186/s12891-019-3002-5
                </li>
            </ol>
        </BlogPostOld>

    )
}